<template>
    <div class="modal-container">
        <p class="font-active title">Terms & Conditions</p>
        <!-- <h3 class="flex-1 w-100 mob_hide">A Valentine’s to <br> Remember</h3> -->
        <!-- <div class="image-container">
            <img width="1365" height="1400" src="~/assets/home/bf_terms_and_conditions.webp" alt="terms and conditions">
            <img src="/assets/valentines/secretlove.webp" class="radious_four" width="1357" height="1077" alt="serect to love code">
        </div> -->
        <div class="text-container">
           
            <p class="mb-3">Diamonds as unique as you and your story. Enjoy Flat 40% off all engagement rings and up to 55% off fine jewelry during our Sunkissed Spring offer. Don't miss your chance to capture forever at these special prices. 
            </p>
            <p class="grey-text mb-0">*Offer excludes loose diamonds. Friendly Diamonds reserves the right to modify or end this promotion at any time without prior notice.</p>
            <!-- <div class="d-flex justify-content-between align-items-start gap-2"> -->
                <!-- <p class="mb-0">For a love that is</p> -->
                <!-- <h3 class="m-0 flex-1 w-100">BLACK FRIDAY SALE</h3> -->
                <!-- <h3 class="m-0 flex-1 w-100">CHRISTMAS SALE</h3> -->
                <!-- <h3 class="m-0 flex-1 w-100 desk_hide">A Valentine’s to <br> Remember</h3> -->
            <!-- </div> -->
            
            <!-- <h3 class="limit flex-1 w-100">Limitless. Unstoppable. Infinite.</h3> -->
            <!-- <p class="grey-text">Make the most of our Black Friday Sale- The best one yet! Explore amazing savings on select jewelry settings, pre-designed rings, and fine jewelry:</p> -->
            <!-- <p class="grey-text">Make the most of our Christmas Sale- The best one yet! Explore amazing savings on Engagement Rings and Fine Jewelry. Offer not applicable on Loose Diamonds and High Jewelry Collections (Soirée and Hues).  
            </p> -->
            <!-- <p class="grey-text">Make the most of our Christmas Sale- The best one yet! Explore amazing savings on Engagement Rings and Fine Jewelry. Offer not applicable on Loose Diamonds and High Jewelry Collections (Soirée and Hues). </p> -->
            <!-- <p class="grey-text mb-2">
                <span>
                    Catch the End of Season Sale 
                </span><br>
                <span>Flat 40% OFF* Sitewide!</span>
            </p> -->
            <!-- <p class="mb-3">Say <b>
                “I love you” 
            </b>
            with an additional discount on Engagement Rings & Fine Jewelry!
              <br>  Offer *Excludes Loose Diamonds.</p> -->


              <!-- <p class="secret mb-2">
                Use the secret code to get an additional 10% off on Engagement Rings and Fine Jewelry!
              </p>
              <p class="secret mb-2">
                So, what are you waiting for?! This special offer ends February 14th, 2025, at 11:59PM EST.
              </p> -->

            <!-- <p class="grey-text text-bold mb-1">Enjoy FLAT 40% OFF Sitewide</p> -->

            <!-- <p class="grey-text">These limited-time offers end on <span class="highlight">December 25th, 2024, at 11:59 PM EST.</span> Friendly Diamonds reserves the right to modify these promotions at any time.</p> -->


            <!-- <p class="grey-text mb-0">Hurry—this special offer ends <b>February 14th, 2025, at 11:59 PM EST.</b> <br>
                Friendly Diamonds reserves the right to modify or end this promotion at any time.</p> -->
           
        </div>
    </div>
</template>
 
 <script setup>

 </script>
 
 <style scoped>
   
     .largesvg {
         transform: scale(1.3);
         cursor: pointer;
         margin-bottom: 2px;
     }
     .modal-container{
         /* display: flex;
         border-radius: 8px;
         overflow: hidden;
         justify-content: center; */
     }
     .image-container{
         /* max-width: 52%; */
         /* display: flex; */
         /* width: 40%; */
         /* border: 1px solid red;  */
     }
     /* .mob_hide, .mob_hide br{
        display: none;
     } */
     .limit{
        margin-bottom: 16px;
     }
     .text-container{
        color : #606060;
         /* padding: 24px; */
     }
     .love_text{
        color: #353535;
        font-weight: 600;
     }
     h3{
        text-align: left;
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #212529;
     }
     .secret{
        color: #212529;
        font-size: 16px;
     }
     a{
         color: var(--green);
         display: block;
     }
 
 
     .title{
        color : #212529;
        font-size: 22px;
        font-weight: 400;
        margin-bottom: 10px;
     }
     .paddit{
        margin-bottom: 20px;
     }
     h3, li{
         margin-bottom: 1rem;
     }
 
     span{
         font-weight: bold;
         color: #353535;
     }
     
     /* ul li::marker {
         color: #212529;
     } */

     .grey-text{
        color: #606060;
        font-style: italic;
        font-size: 14px;
        line-height: 18px;
     }

     .text-bold{
        color: #353535;
        line-height: 24px;
        font-weight: bold;  
     }
 
     @media(max-width:767px){
        .modal-container{
            flex-direction: column;
        }
        .image-container{
            max-width: 100%;
            margin: 0 0 12px 0;
            display: none;
            /* width: 40%; */
         /* border: 1px solid red;  */
     }
     .title{
        font-size: 20px;
        margin-bottom: 16px;
     }
     .paddit{
        margin-bottom: 16px;
     }
        .limit,  .secret{
            margin-bottom: 8px;
            font-size: 14px;
        }
        .desk_hide{
            display: none;
        }
        .mob_hide{
            display: block;
            font-size: 16px;
            text-align: center;
        }
        .text-container{
            padding: 0;
            max-width: 100%;
        }
         h3{
             font-size: 16px;
         }
 
         li,p{
             font-size: 14px;
         }
 
         h3, li{
             margin-bottom: 16px;
         }
     }
 
     @media (max-width : 500px){
       .mob_hide br{
        display: block;
     }
     }
 </style>
